import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sst-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  btnConfirmText: string = 'Confirm';
  showCancel: boolean = true;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    if (this.data.btnConfirmText) {
      this.btnConfirmText = this.data.btnConfirmText;
    }
    if (this.data.showCancel) {
      this.showCancel = this.data.showCancel;
    }
  }
}
